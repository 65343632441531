.main-header {
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 10;
}

.content {
  margin-top: 3.5rem;
  padding: 1rem;
}

@media (min-width: 768px) {
  .content {
    margin-top: 3.5rem;
    padding: 1rem 2rem;
  }
}
