* {
  box-sizing: border-box;
}

body {
  font-family: sans-serif;
}

.no-margin {
  margin: 0 !important;
}
