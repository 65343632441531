.input {
  margin: 1rem 0;
  width: 100%;
}
.input.flex {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.input > div {
  flex: 0 0 90%;
}

.input label {
  display: block;
  margin-bottom: 0.3rem;
}

.input input,
.input textarea {
  display: block;
  font: inherit;
  padding: 0.25rem 0.5rem;
  width: 100%;
  border-radius: 3px;
  border: 1px solid #ccc;
}

.input .touched.invalid {
  border-color: red;
  background: #ffc2c2;
}

.input input:focus,
.input textarea:focus {
  outline: none;
  border-color: #3b0062;
  color: #3b0062;
}
